/* 
 * @Author: 曹俊杰 
 * @Date: 2021-01-14 15:43:49
 * @Module: 确认订单
 */
 <template>
  <div v-if="data">
    <div class="confirmOrder-box">
      <div class="confirmOrde-content">
        <div class="confirmOrde-content-t">
          <div class="title">商品信息</div>
          <div class="confirmOrde-content-t-item">
            <el-image style="width: 80px;height: 80px;border-radius: 4px;" fit="contain" :src="data.cover" />
            <div class="confirmOrde-content-t-item-c">
              <div>{{data.title}}</div>
              <div>共{{data.chapter_count}}节</div>
            </div>
            <div class="confirmOrde-content-t-item-r">
              {{data.price}}金币
            </div>
          </div>
        </div>
        <div class="confirmOrde-content-c">
          <div class="title">选择支付方式</div>
          <div class="confirmOrde-content-c-play">
            <div class="confirmOrde-content-c-play-l">
              <img src="@/static/homepage/金币支付.png">
              <div>金币支付（金币余额：{{order}}）</div>
            </div>
            <div class="confirmOrde-content-c-play-r">
              <span class="info hover" @click="$router.push({name:'wallet'})">余额不足，请充值</span>
              <el-checkbox v-show="data.price<=order&&data" v-model="goldPlay"></el-checkbox>
            </div>
          </div>
          <!-- <div class="confirmOrde-content-c-play">
            <div class="confirmOrde-content-c-play-l">
              <img src="@/static/homepage/兑换码.png">
              <div>兑换码</div>
            </div>
            <div class="confirmOrde-content-c-play-r">
              <div @click="showCDKEY"
                   class="hover">点击使用兑换码</div>
              <div v-show="showCDKEY"
                   class="showCDKEY-box">
                <div class="showCDKEY">
                  <input type="text"
                         v-model="CDKEY"
                         placeholder="请输入您的兑换码">
                  <div class="hover btn">点击验证</div>
                </div>
                <div @click="showCDKEY=false"
                     class="bt hover">取消</div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="confirmOrde-footer">
      <div class="confirmOrde-footer-content">
        <div class="userinfo">当前登录账号：{{userInfo.phone}}</div>
        <div class="r">
          <div class="gold">{{data.price}}金币</div>
          <div class="btn hover" @click="buyBtn">立即购买</div>
        </div>
      </div>
    </div>
    <!-- <cdkey-module ref="cdkeyModule" /> -->
  </div>
</template>
 <script>
import userInfo from "@/mixins/userinfo"
// import cdkeyModule from "@/views/college/module/cdkeyModule"
export default {
  components: {
    // cdkeyModule
  },
  mixins: [userInfo],
  data () {
    return {
      data: null,
      order: 0,
      goldPlay: false   // 金币支付
    };
  },
  mounted () {
    const { query } = this.$router.history.current
    this.data = query
    this._get_orderDetail()
  },
  methods: {

    async _get_orderDetail () {
      const { status, data } = await this.$api.orderOrderDetailApi()
      console.log(status, data)
      if (status == 200) {
        this.order = data
      }
    },
    buyBtn () {  //  购买按钮点击事件
      if (this.goldPlay) {
        this._get_coinPay()
      } else {
        this.$message.warning("请选择支付方式")
      }
    },
    async _get_coinPay () {    //  请求购买课程
      /*
      *product_id:'课程的uuid'
      *product_type：0 //产品的类型，因为购买课程只能为0 
      */
      const { course_id: product_id } = this.data
      const { status, info } = await this.$api.coinPayApi({
        product_id,
        product_type: 0
      })
      if (status == 200) {
        this.$message.success(info)
        // this.$router.push({
        //   name: 'homepage'
        // })
      } else {
        this.$message.error(info)
      }
    },
  },
};
 </script>
 <style lang='scss' scoped>
.confirmOrder-box {
  width: 1240px;
  min-height: calc(100vh - 92px);
  margin: 0 auto;
}
.confirmOrde-content {
  width: 1000px;
  margin-left: 156px;
  margin-top: 30px;
  padding: 0 13px;
  .title {
    line-height: 32px;
    padding-top: 16px;
    padding-left: 26px;
  }
  &-t {
    background: #fff;
    border-top: 4px solid #df2a29;
    border-radius: 4px;
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.08);
    padding-bottom: 16px;
    &-item {
      display: flex;
      padding: 0 26px;
      height: 108px;
      align-items: center;
      &-c {
        width: 716px;
        margin-left: 24px;
        :nth-child(1) {
          color: #262626;
          font-size: 16px;
        }
        :nth-child(2) {
          font-size: 12px;
          color: #666;
          margin-top: 10px;
        }
      }
      &-r {
        font-size: 18px;
        color: #262626;
      }
    }
  }
  &-c {
    margin-top: 20px;
    background: #fff;
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    &-play {
      display: flex;
      margin: 0 26px;
      height: 70px;
      align-items: center;
      border-bottom: 1px solid #eee;
      &:last-child {
        border-bottom: none;
      }
      &-l {
        display: flex;
        align-items: center;
        flex: 1;
        img {
          margin-right: 6px;
          width: 25px;
          height: 25px;
        }
      }
      &-r {
        .info {
          color: #df2a29;
          font-size: 16px;
        }
        .showCDKEY-box {
          display: flex;
          align-items: center;
          .bt {
            padding-left: 20px;
            color: #262626;
            font-size: 14px;
          }
        }
        .showCDKEY {
          width: 332px;
          height: 42px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #eeeeee;
          display: flex;
          input {
            flex: 1;
            padding-left: 16px;
            font-size: 14px;
          }
          .btn {
            width: 100px;
            line-height: 42px;
            background: #cccccc;
            text-align: center;
            color: #fff;
            font-size: 14px;
          }
        }
      }
    }
  }
}
.confirmOrde-footer {
  height: 76px;
  background: #ffffff;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
  position: fixed;
  bottom: 0px;
  width: 100%;
  &-content {
    display: flex;
    width: 1240px;
    margin: 0 auto;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    .userinfo {
      font-size: 14px;
      color: #666666;
    }
    .r {
      display: flex;
      align-items: center;
      .gold {
        line-height: 40px;
        font-size: 28px;
        color: #df2a29;
        margin-right: 20px;
      }
      .btn {
        width: 180px;
        line-height: 46px;
        text-align: center;
        background: linear-gradient(90deg, #f15453 0%, #df2a29 100%);
        border-radius: 23px;
        color: #fff;
      }
    }
  }
}
</style>